import type { Modal } from '@workrate/trailblazer-ui';
import type { AsyncComponentLoader } from 'vue';

export interface ModalOptions {
  title?: string
  subheading?: string
  actions?: Modal['actions']
  confirmButtonText?: string
  cancelButtonText?: string
  closeOnClick?: boolean
  beforeClose?: Modal['beforeClose']
  onClose?: () => void
  width?: string
}

export interface CreateModalOptions {
  component?: AsyncComponentLoader<any> | InstanceType<any>
  options: ModalOptions
  props: Record<string, unknown>
}

const modalState = reactive<
  CreateModalOptions & { componentRef?: unknown, modalRef?: Modal }
>({
  component: undefined,
  componentRef: undefined,
  modalRef: undefined,
  options: {},
  props: {},
});

export function useModal() {
  const onClose = () => (modalState.component = undefined);

  const createModal = ({ options, component, props }: CreateModalOptions) => {
    const handleClose = () => {
      if (typeof options.onClose === 'function')
        options.onClose();

      onClose();
    };

    modalState.options = {
      ...options,
      onClose: handleClose,
    };

    modalState.props = props;

    // Need to use `markRaw` to prevent component from being made
    // reactive (performance overhead)
    if (component !== undefined)
      modalState.component = markRaw(component);
  };

  return reactive({
    createModal,
    setBeforeClose: (callback: Modal['beforeClose']) =>
      (modalState.options.beforeClose = callback),
    setComponentRef: (element?: unknown) => {
      modalState.componentRef = element;
    },
    setModalRef: (element?: Modal) => {
      modalState.modalRef = element;
    },
    componentRef: computed(() => modalState.componentRef),
    modalRef: computed(() => modalState.modalRef),
    state: computed(() => modalState),
  });
}
